<template>
  <div class="case">
    <!-- 轮播图 -->
    <div class="swiper-box">
      <swiper :options="swiperOption"
              ref="mySwiper"
              class="swiper-wrapper"
              @slide-change-transition-end="handleClickSlide">
        <swiper-slide v-for="(item, index) in swiperList.items"
                      :key="index"
                      class="swioer-item"
                      swiper-animate-effect="shutter2">
          <div class="swiper-box">
            <img :src="item.imgs?(JSON.parse(item.imgs)[langtype]?JSON.parse(item.imgs)[langtype]:item.img):item.img"
                 alt="" />
            <!-- <div :class="'center' + index">
              <p class="name">{{item.name}}</p>
              <p class="title">{{item.title}}</p>
              <p class="description">{{item.description}}</p>
              <div class="btn-box" v-if="index !== 0">
                <span>立即选购 </span>|
                <span>  了解详情</span>
              </div>
            </div> -->
          </div>
        </swiper-slide>
        <div class="swiper-pagination"
             slot="pagination"></div>
      </swiper>
      <!-- <div class="sign-box">
        <img class="sign" src="../assets/home/shubiao.png" alt="" />
      </div> -->
    </div>
    <!-- 轮播图 -->
    <div class="clear"></div>
    <div class="inmain clearfix">
      <div class="case clearfix">
        <div class="container containers">
          <ul class="caselist clearfix wow fadeInUp"
              data-wow-duration="1.5s"
              data-wow-delay="0.1s">
            <li v-for="(item,index) in casesImg.items"
                @click="addCasesImg(item.id)"
                :key="index">
              <a>
                <div class="box">
                  <div class="pic">
                    <el-image class="middleCenter"
                              :src="item.coverImg"
                              alt=""
                              lazy></el-image>
                  </div>
                  <div class="bt">{{item.titles?JSON.parse(item.titles)[langtype]||item.title:item.title}}</div>
                </div>
              </a>
            </li>
          </ul>
          <div style="margin-bottom:10px;">
            <el-pagination @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           :current-page.sync="currentPage1"
                           :page-size="count"
                           layout="total, prev, pager, next"
                           :total="total">
            </el-pagination>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  props: [],
  components: { swiper, swiperSlide },
  computed: {
    swiper () {
      return this.$refs.mySwiper.swiper;
    },
  },
  data () {
    return {
      cases: [],
      casesImg: [],
      currentPage1: 1,
      total: null,
      page: 1,
      count: 12,
      langtype: 0,
      swiperOption: {
        loop: true, // 是否循环轮播
        autoplay: true, // 是否可以自动轮播
        slidesPerView: 1, // 可是区域内可展示多少个块
        spaceBetween: 30, // 块之间间隔距离
        initialSlide: 0, // 默认初始显示块
        freeMode: false,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      swiperList: [
        // {img: require("../assets/home/57f86325-aa72-4430-840b-85669b889a7a.jpg")},
        // {img: require("../assets/home/718efa0b-9f6d-48dc-a42f-b6ae0b1b63de.jpg")},
        // {img: require("../assets/home/a71f77f4-b971-457f-83c6-e5751782ba02.jpg")},
        // {img: require("../assets/home/ed000602-e323-48e2-b90d-0affc0aeb8f9.jpg")},
      ],
    }
  },
  name: "case",
  mounted () {
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      this.$wow.init();
    });
  },
  created () {
    switch (localStorage.getItem('lang')) {
      case "zh":
        this.langtype = 0;
        break;
      case "en":
        this.langtype = 1;
        break;
      case "es":
        this.langtype = 6;
        break;
      case "ru":
        this.langtype = 2;
        break;
      case "de":
        this.langtype = 3;
        break;
      case "fr":
        this.langtype = 4;
        break;
      case "ar":
        this.langtype = 5;
        break;
    }
    this.getList()
  },
  methods: {
    handleClickSlide () { },
    async getList () {
      let cases = await this.api.get(this.apiPath.cases, {});
      this.cases = cases
      let data = {
        layout_id: 28,
        page: this.page,
        count: this.count
      }
      let casesImg = await this.api.post(this.apiPath.editor, data);
      this.casesImg = casesImg
      console.log(this.casesImg)
      this.total = casesImg.total
      this.currentPage1 = casesImg.page + 1
      let res = await this.api.get(this.apiPath.caseSwiper, {});

      console.log(res)
      this.swiperList = res;
      if (res.items.length == 0) {
        this.swiperList.items.push({
          img: res.img
        })
      }
    },
    addCasesImg (id) {
      this.$router.push({
        path: this.$router.history.current.matched[0].path + "/casesDetail?id=" + id,
      });
    },
    handleSizeChange (val) {
      // 每页多少条
      this.count = val
      this.getList()
    },
    handleCurrentChange (val) {
      //当前页
      this.page = val
      this.getList()
    },
  },
  watch: {
  }
}
</script>

<style lang="less" scoped>
.case {
  padding: 0;
  margin-top: 80px;
}
.box {
  cursor: pointer;
  .middleCenter {
    width: 100%;
    height: 100%;
  }
}
.containers {
  padding: 0 120px;
}
@media screen and (max-width: 750px) {
  .img img {
    height: 200px;
    object-fit: cover;
  }
  .containers {
    padding: 0 15px;
  }
  .caselist li {
    width: 50%;
    padding: 0 15px;
    .pic {
      height: 110px;
    }
  }
  .caselist li a .bt {
    line-height: 30px;
  }
}
.swiper-box {
  position: relative;
  .btn-box {
    font-size: 20px;
    margin-top: 10px;
    span {
      color: rgb(95, 139, 200);
    }
  }
  .center0 {
    width: 100%;
    position: absolute;
    top: 240px;
    left: 30px;
    text-align: center;
    .name {
      font-size: 60px;
      color: #fff;
    }
    .title {
      font-size: 25px;
      color: #fff;
    }
    .description {
      font-size: 10px;
      color: #fff;
    }
  }
  .center1 {
    width: 40%;
    position: absolute;
    top: 35%;
    left: 80px;
    text-align: center;
    color: #000;
    .name {
      font-size: 22px;
    }
    .title {
      font-size: 50px;
    }
    .description {
      font-size: 30px;
    }
  }
  .center2 {
    width: 40%;
    position: absolute;
    top: 35%;
    right: 190px;
    text-align: center;
    color: #fff;

    .name {
      font-size: 22px;
    }
    .title {
      font-size: 50px;
    }
    .description {
      font-size: 30px;
    }
  }
  .center3 {
    width: 40%;
    position: absolute;
    top: 35%;
    left: 30px;
    text-align: center;
    color: #fff;

    .name {
      font-size: 22px;
    }
    .title {
      font-size: 50px;
    }
    .description {
      font-size: 30px;
    }
  }
  .center3 {
    width: 40%;
    position: absolute;
    top: 35%;
    left: 30px;
    text-align: center;
    color: #fff;
    .name {
      font-size: 22px;
    }
    .title {
      font-size: 50px;
    }
    .description {
      font-size: 30px;
    }
  }
}
</style>
